import { useContext } from "react";
import NeoKeContext from "context";

import { CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { file2Base64 } from "@pankod/refine-core";
import { Button, DatePicker, Form, Input, Result, Upload } from "antd";
import { httpsCallable } from "firebase/functions";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import { firestore, functions } from "../../util/firebase";
const { dbError } = firestore;

function CorporateCredentialForm({ setComplete, header }) {
  const { handleWebsiteMode } = useContext(NeoKeContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(false);
  const [mail, setMail] = useState(undefined);
  const formRef = useRef();

  const dispatcher = httpsCallable(functions, "dispatcher");

  const isAgency = window.location.pathname == "/agency";

  const resizeImage = (image, extension) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        image,
        150,
        150,
        extension,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
      );
    });

  const onFinish = async (values) => {
    setLoading(true);

    let result = { ...values };
    result.date_of_birth = result.date_of_birth.format("YYYY/MM/DD");
    result.passport_expiry_date =
      result.passport_expiry_date.format("YYYY/MM/DD");

    const image = result.selfie[0];
    const mime_type = image.type;
    const name = image.name;
    const extension = mime_type.split("/")[1].toUpperCase();
    let selfie = await file2Base64(image);
    selfie = await resizeImage(result.selfie[0].originFileObj, extension);

    result.selfie = JSON.stringify({
      "mime-type": mime_type,
      name: name,
      extension: extension,
      base64: selfie,
    });
    result.agency = isAgency;

    result.dispatch = "createCorporateCredentialCache";
    dispatcher(result)
      .then(() => {
        setLoading(false);
        setMail(result.email);
        setRequested(true);
        setComplete(true);
      })
      .catch((error) => {
        dbError(error);
        setLoading(false);
      });
  };

  const renderForm = () => {
    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }

      return e?.fileList;
    };

    return (
      <Form
        {...{
          layout: "vertical",
        }}
        ref={formRef}
        name="reserve"
        onFinish={onFinish}
      >
        <Form.Item
          name="first_name"
          label={t("form.generic.field.first_name")}
          rules={[
            {
              required: true,
              message: t(
                "form.corporatecredential.field.validation.first_name",
              ),
            },
          ]}
        >
          <Input
            placeholder={t(
              "form.corporatecredential.field.placeholder.first_name",
            )}
          />
        </Form.Item>
        <Form.Item
          name="last_name"
          label={t("form.generic.field.last_name")}
          rules={[
            {
              required: true,
              message: t("form.corporatecredential.field.validation.last_name"),
            },
          ]}
        >
          <Input
            placeholder={t(
              "form.corporatecredential.field.placeholder.last_name",
            )}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label={t("form.generic.field.email")}
          rules={[
            {
              type: "email",
              message: t("form.generic.field.validation.valid_email"),
            },
            {
              required: true,
              message: t("form.corporatecredential.field.validation.email"),
            },
          ]}
        >
          <Input
            placeholder={t("form.corporatecredential.field.placeholder.email")}
          />
        </Form.Item>
        <Form.Item
          name="selfie"
          label={t("form.generic.field.selfie")}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: t("form.generic.field.validation.selfie"),
            },
          ]}
        >
          <Upload
            name="selfie"
            beforeUpload={() => false}
            accept="image/png, image/jpeg, image/jpg"
            listType="picture"
          >
            <Button icon={<UploadOutlined />} block>
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="date_of_birth"
          label={t("form.generic.field.date_of_birth")}
          rules={[
            {
              required: true,
              message: t("form.generic.field.validation.date_of_birth"),
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="passport_number"
          label={t("form.generic.field.passport_number")}
          rules={[
            {
              required: true,
              message: t(
                "form.corporatecredential.field.validation.passport_number",
              ),
            },
          ]}
        >
          <Input
            placeholder={t(
              "form.corporatecredential.field.placeholder.passport_number",
            )}
          />
        </Form.Item>
        <Form.Item
          name="passport_country"
          label={t("form.generic.field.passport_country")}
          rules={[
            {
              required: true,
              message: t(
                "form.corporatecredential.field.validation.passport_country",
              ),
            },
          ]}
        >
          <Input
            placeholder={t(
              "form.corporatecredential.field.placeholder.passport_country",
            )}
          />
        </Form.Item>
        <Form.Item
          name="passport_expiry_date"
          label={t("form.generic.field.passport_expiry_date")}
          rules={[
            {
              required: true,
              message: t("form.generic.field.validation.passport_expiry_date"),
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item style={{ marginBottom: "0px" }} {...{}}>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            className="last"
            loading={loading}
            block
          >
            {t(
              `form.corporatecredential.button.${isAgency ? "agency" : "user"}`,
            )}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderInfo = () => {
    return (
      <div className="corporate-credential-result">
        <Result
          status="success"
          icon={<CheckCircleOutlined />}
          title={t("form.corporatecredential.result.title")}
          subTitle={
            <Trans i18nKey="form.corporatecredential.result.description">
              &nbsp;<p></p>&nbsp;<b>{{ mail }}</b>&nbsp;
            </Trans>
          }
          extra={
            isAgency
              ? [
                  <Button
                    type="primary"
                    key="go"
                    onClick={() => {
                      window.location = "/";
                    }}
                  >
                    Go home
                  </Button>,
                  <Button key="create" onClick={() => window.location.reload()}>
                    Create a new credential
                  </Button>,
                ]
              : [
                  <Button
                    type="primary"
                    key="go"
                    onClick={() => handleWebsiteMode("user")}
                  >
                    Continue with your reservation
                  </Button>,
                ]
          }
        />
      </div>
    );
  };

  return (
    <>
      <h2 display={header ? "block" : "none"}>{header}</h2>
      {requested ? renderInfo() : renderForm()}
    </>
  );
}

export default CorporateCredentialForm;
