import { useState } from "react";

import CorporateCredentialForm from "components/forms/CorporateCredentialForm";

import { t } from "i18next";
import { Row } from "antd";

function CorporateCredentialPanel() {
  const [complete, setComplete] = useState(false);
  return (
    <Row justify="space-around" align="middle">
      <div className="panel ant-col-lg-10 ant-col-md-16 ant-col-sm-22 ant-col-xs-24">
        {complete ? (
          <></>
        ) : (
          <>
            <h1>
              {t(
                `section.corporatecredential.title.${
                  window.location.pathname == "/agency" ? "agency" : "user"
                }`,
              )}
            </h1>
            <h2>{t("section.corporatecredential.subtitle")}</h2>
          </>
        )}
        <CorporateCredentialForm setComplete={setComplete} header="" />
      </div>
    </Row>
  );
}

export default CorporateCredentialPanel;
