import { useContext } from "react";
import NeoKeContext from "context";

import { Layout } from "antd";
const { Header } = Layout;

import MenuItems from "components/menus/MenuItems";
import AccountMenu from "components/menus/AccountMenu";

const WebsiteHeader = () => {
  const { websiteMode } = useContext(NeoKeContext);

  return websiteMode == "user" || websiteMode == "hotel" ? (
    <Header>
      <div className="left">
        <MenuItems />
      </div>
      <div className="center empty"></div>
      <div className="right">
        <AccountMenu endpoint={"/account"} adminEndpoint={"/admin"} />
      </div>
    </Header>
  ) : (
    <></>
  );
};

export default WebsiteHeader;
