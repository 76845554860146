import CorporateCredentialPanel from "components/panels/CorporateCredentialPanel";

import PortalLogo from "../../images/logo.png";

const CorporateCredential = () => {
  return (
    <div className="container corporate-credential-container ant-col-24">
      <img src={PortalLogo} alt="Logo" className={"neoke-logo"} />
      <CorporateCredentialPanel />
    </div>
  );
};

export default CorporateCredential;
