import HotelBooking from "../../images/hotel-booking-ota.svg";
import { Row } from "antd";
import { t } from "i18next";

const Home = () => {
  return (
    <div className="home-container ant-col-24">
      <Row justify="space-evenly" align="middle">
        <div className="ant-col-md-7 ant-col-sm-24 ant-col-xs-24">
          <h1>{t("section.home.title")}</h1>
          <h2>{t("section.home.subtitle")}</h2>
        </div>
        <div className="ant-col-md-9 ant-col-sm-24 ant-col-xs-24">
          <img src={HotelBooking} alt="Home cover" />
        </div>
      </Row>
    </div>
  );
};

export default Home;
